import React, { useEffect, useState } from 'react'
import { Table, Select, Button, Modal, Form, Row, Col, Input, message, Alert, Flex, Card, Space, Typography, Popconfirm } from "antd";
import AxiosService from '../../services/APIService';
import { CloseOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import useBroker from '../../hooks/useBroker';
import useServer from '../../hooks/useServer';
import { connect } from 'react-redux';
import useCustomer from '../../hooks/useCustomer';
import { Link } from 'react-router-dom';
import { downloadFileHandle } from '../../utils/commonFunc';
import axios from 'axios';
import UserEdit from './UserEdit';
import moment from 'moment';
const { Option } = Select;

function UserRequest({ permission, user, isLoading }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const axiosService = new AxiosService()
    const [userList, setUserList] = useState();
    const { brokerList } = useBroker()
    const { serverList } = useServer()
    const [searchText, setSearchText] = useState("");
    const [filterShow, setFilterShow] = useState(false);
    const { customerList } = useCustomer({})
    const [isEditModal, setIsEditModal] = useState(false)
    const [editData, seteditData] = useState(null);
    const [filteredList, setFilteredList] = useState(null);

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            width: "40px"
        },
        {
            title: "Name",
            dataIndex: "fullname",
            key: "name",
            width: "250px"
        },
        {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            width: '150px',
            render: (e, rec) => <Select
                style={{ width: '150px' }}
                defaultValue={e}
                value={e}
                // disabled={!rec.action.account_number}
                onChange={(val) => statusChange(val, rec)}
                options={[
                    { value: 'APPROVED', label: 'Approve' },
                    { value: 'REJECTED', label: 'Reject' }
                ]}
            />
        }, {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e, rec) => (permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Space>
                <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => {
                    setIsEditModal(true)
                    seteditData(rec)
                }} />
                <Popconfirm
                    description="Are you sure you want to delete this account?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteUser(rec.key)}
                >
                    <Button icon={<CloseOutlined />} danger size={"middle"} />
                </Popconfirm>
            </Space>
        }
    ];

    useEffect(() => {
        fetchList({})
    }, []);

    const deleteUser = (id) => {
        axios.delete(`${process.env.REACT_APP_ADMIN_API_URL}/delete-user/${id}`, {
            headers: {
                'x-product-type': 'PIP_GRID'
            }
        })
            .then((res) => {
                fetchList({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }

    const statusChange = (val, rec) => {
        axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/update-request-status`, {
            userId: rec?.key, status: val
        }, {
            headers: {
                'x-product-type': 'PIP_GRID'
            }
        }).then(res => {
            message.success(res.message)
            fetchList({})
        })
            .catch(e => message.error(e.message))
    }

    const fetchList = () => {
        axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/login-requests`, {
            headers: {
                'x-product-type': 'PIP_GRID'
            }
        })
            .then(res => {
                const data = res.data.requests.map((dt, i) => ({
                    count: `${i + 1}`,
                    key: dt?._id,
                    fullname: `${dt?.fullName || ''}`,
                    phoneNumber: dt?.phoneNumber || '-',
                    dob: dt?.dob ? moment(dt?.dob, "YYYY-MM-DD").format("DD-MM-YYYY") : '-',
                    email: dt?.email || '-',
                    status: dt?.loginRequestStatus && "Pending",
                    address: dt?.address || '',
                    productName: dt?.productName || ''
                }));
                setUserList(data)
            }).catch(e => {
                console.log(e);
            })
    }

    return (
        <>
            <Typography.Title level={3} className="page-title">Request</Typography.Title>
            <Flex justify="end" gap={5} style={{ marginBottom: "20px" }}>
                {/* <Input
                    style={{ maxWidth: 250 }}
                    placeholder="Search by Name, Email, or Phone..."
                    onChange={handleSearch}
                />
                <Button type="primary" onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button> */}
                <Button type="primary" onClick={() => downloadFileHandle(userList, 'pending_users')}>
                    Download
                </Button>
            </Flex>
            {/* {filterShow && (<Card style={{ marginBottom: "20px" }}>
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="refName">
                                <Select placeholder="Select Reference" allowClear showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="brokerName">
                                <Select allowClear placeholder="Select Broker">
                                    {brokerList.map(bkr => <Option key={bkr._id} value={bkr._id}>{bkr.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="ibName">
                                <Select allowClear placeholder="Select IB">
                                    {[...new Set(brokerList.map(item => item.ib_name))].map(bkr => <Option key={bkr} value={bkr}>{bkr}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)} */}
            <Table loading={isLoading} columns={columns} dataSource={userList} />
            {/* : <Alert message="You havn't Enough Permission" /> */}
            {isEditModal && (
                <UserEdit
                    editData={editData}
                    isLoading={false}
                    onClose={() => setIsEditModal(false)}
                    fetchList={fetchList}
                />
            )}
        </>
    );
}
const mapStateToProps = ({ login, loading }) => {
    const { permission, user } = login
    const { isLoading } = loading
    return { permission, user, isLoading }
}
export default connect(mapStateToProps, {})(UserRequest)