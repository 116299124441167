import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import totalClientIcon from '../../assets/total_client.svg'
import activeClientIcon from '../../assets/active_client.svg'
import totalBalIcon1 from '../../assets/total_balance_i1.svg'
import totalBalIcon2 from '../../assets/total_balance_i2.svg'
import PendingUserIcon from '../../assets/total_pending_user.svg'
import totalProfitIcon from '../../assets/total_profit.svg'
import botChargeIcon from '../../assets/total_botcharge.svg'
import totalDepositIcon from '../../assets/total_deposite_balance.svg'
import brokerIcon from '../../assets/total_broker.svg'
import axios from 'axios'

function Dashboard() {
    const [dashboard, setDashboard] = useState();
    useEffect(() => {
        fetchDashboardData()
    }, [])

    const fetchDashboardData = (val) => {
        axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/admin-dashboard`, {
            headers: {
                'x-product-type': 'PIP_GRID'
            }
        })
            .then(res => {
                setDashboard(res.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <Row gutter={[20, 20]}>
            <Col span={24}>
                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <div className='dashboard-block' style={{ backgroundColor: "#00C39A", padding: "50px 10px 24px 10px", textAlign: "center" }}>
                                    <h3>{dashboard?.totalLeads?.toLocaleString("hi-IN")}</h3>
                                    <span style={{ marginBottom: 34 }}>Total Leads</span>
                                    <img src={PendingUserIcon} style={{ width: "244px", margin: "auto" }} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <div className='dashboard-block' style={{ backgroundColor: "#FFD155", padding: "86px 10px 86px 50px" }}>
                                    <h3>{(dashboard?.totalUsers)?.toLocaleString("hi-IN")}</h3>
                                    <span>Total users</span>
                                    <img src={totalBalIcon1} style={{ width: "70px", top: 24, left: 24 }} className='position' />
                                    <img src={totalBalIcon2} style={{ width: "147px", bottom: 18, right: 25 }} className='position' />
                                </div>
                            </Col>
                            <Col span={10}>
                                <div className='dashboard-block' style={{ backgroundColor: "#f9a19e", padding: "45px 10px 45px 25px" }}>
                                    <h3>{dashboard?.inactiveUsers || 0}</h3>
                                    <span>Inactive Users</span>
                                    <img src={totalClientIcon} style={{ width: "40px", top: 13, right: 12 }} className='position' />
                                </div>
                            </Col>
                            <Col span={14}>
                                <div className='dashboard-block' style={{ backgroundColor: "#FF904B", padding: "45px 10px 45px 50px" }}>
                                    <h3>{dashboard?.activeUsers || 0}</h3>
                                    <span>Active Users</span>
                                    <img src={activeClientIcon} style={{ width: "50px", top: 13, right: 29 }} className='position' />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <div className='dashboard-block' style={{ backgroundColor: "#FCC699", padding: "40px 10px 20px 10px", textAlign: "center" }}>
                                    <h3>${dashboard?.totalEarnings?.toLocaleString("hi-IN")}</h3>
                                    <span style={{ marginBottom: 34 }}>Total Earnings</span>
                                    <img src={totalProfitIcon} style={{ width: "283px", margin: "auto" }} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[20, 20]}>
                            <Col span={6}>
                                <div className='dashboard-block' style={{ backgroundColor: "#C89AFF", padding: "30px 20px 20px 30px" }}>
                                    <h3>{dashboard?.productSales[2]?.productSelling?.toLocaleString("hi-IN")}</h3>
                                    <span style={{ marginBottom: 40 }}>Product a (Total sell)</span>
                                    <img src={botChargeIcon} style={{ width: "72px", marginLeft: "auto" }} />
                                </div>
                            </Col>
                            <Col span={9}>
                                <div className='dashboard-block' style={{ textAlign: "center", backgroundColor: "#FA7565", padding: "50px 20px 50px 20px" }}>
                                    <h3>{dashboard?.productSales[1]?.productSelling?.toLocaleString("hi-IN")}</h3>
                                    <span>Product b (Total sell)</span>
                                    <img src={totalDepositIcon} style={{ width: "51px", top: 11, left: 11 }} className='position' />
                                    <img src={totalDepositIcon} style={{ width: "51px", bottom: 11, right: 11 }} className='position' />
                                </div>
                            </Col>
                            <Col span={9}>
                                <div className='dashboard-block' style={{ textAlign: "center", backgroundColor: "#79DB70", padding: "50px 10px 100px 20px" }}>
                                    <h3>{dashboard?.productSales[0]?.productSelling?.toLocaleString("hi-IN")}</h3>
                                    <span>Product ab (Total sell)</span>
                                    <img src={totalDepositIcon} style={{ width: "51px", top: 11, left: 11 }} className='position' />
                                    <img src={brokerIcon} style={{ width: "57px", bottom: 4, right: 10 }} className='position' />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Dashboard