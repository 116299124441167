import { BellOutlined, CheckOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Menu, notification, Typography } from "antd";
import React, { useEffect, useState } from "react";
import AxiosService from "../services/APIService";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import webSocketService from "../services/websocketService";
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "1s",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1y",
    yy: "%dy",
  },
});
const Notification = ({ user, type }) => {
  const [notifications, setNotifications] = useState([]);
  const apiService = new AxiosService();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.role) return;

    const roleHandlers = {
      ADMIN: () => {
        webSocketService.onMessage("adminNotification", fetchNotifications);
        fetchNotifications();
      },
      CUSTOMER: () => {
        webSocketService.onMessage("customerNotification", (notifications) => {
          notification.info({ message: notifications });
          myNotifications();
        });
        myNotifications();
      },
      WHITE_LABEL: () => {
        webSocketService.onMessage("whiteLabelNotification", (notif) => {
          if (user._id === notif.user) {
            myNotifications();
          }
        });
        myNotifications();
      },
    };

    const handleRole = roleHandlers[user.role];
    if (handleRole) handleRole();

    return () => {
      webSocketService.offMessage("adminNotification");
      webSocketService.offMessage("customerNotification");
      webSocketService.offMessage("whiteLabelNotification");
    };
  }, [user]);

  const myNotifications = async () => {
    apiService
      .get("/notification/user", {}, false)
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((e) => console.log(e));
  };
  const fetchNotifications = async () => {
    apiService
      .get("/notification/list", {}, false)
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((e) => console.log(e));
  };

  const notificationClick = (id, redirectTo) => {
    webSocketService.sendMessage("readNotification", id);
    let notList = [...notifications];
    notList.forEach((nt) => {
      if (nt._id === id) {
        nt.read = true;
      }
    });
    setNotifications(notList);

    if (redirectTo) {
      navigate(redirectTo);
    }
  };
  const fromNowDate = (date) => {
    return moment(date).fromNow(true);
  };
  const readAllHandle = () => {
    webSocketService.sendMessage("readAllNotification", user?._id);
    let notList = [...notifications];
    notList.forEach((nt) => {
      nt.read = true;
    });
    setNotifications(notList);
  };

  const notificationMenu = (
    <Menu
      style={{
        maxHeight: "300px",
        overflowX: "auto",
        maxWidth: "600px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography.Link onClick={readAllHandle}>Read All</Typography.Link>
      </div>
      {notifications.map((notification) => (
        <Menu.Item onClick={() => notificationClick(notification._id, notification.redirectTo)} key={notification._id}>
          {!notification.read ? (
            <>
              <Badge dot color="rgb(55, 255, 33)" />
              &nbsp;&nbsp;
            </>
          ) : (
            <>
              <CheckOutlined style={{ fontSize: 10 }} />
              &nbsp;
            </>
          )}
          {notification.message}{" "}
          <Typography.Text type="secondary">{fromNowDate(notification.created_at)}</Typography.Text>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown placement="bottomRight" overlay={notificationMenu} trigger={["click"]}>
      <span style={{ cursor: "pointer" }}>
        <Badge count={notifications.filter((ntf) => ntf.read === false).length} overflowCount={99}>
          <BellOutlined style={{ fontSize: 18, color: "#fff" }} />
        </Badge>
      </span>
    </Dropdown>
  );
};
const mapStateToProps = ({ login }) => {
  const { user } = login;
  return { user };
};
export default connect(mapStateToProps, {})(Notification);
