import { Button, Card, Col, DatePicker, Flex, Form, Input, Modal, Row, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

function UserEdit({ editData, isLoading, onClose, fetchList }) {
    const [form] = Form.useForm()

    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                fullname: editData.fullname,
                email: editData.email,
                phoneNo: editData.phoneNumber,
                dob: editData.dob ? moment(editData.dob, "YYYY-MM-DD") : null,
                address: editData.address,
                brokerName: editData.brokerName,
                brokerServer: editData.serverBroker,
                accountNumber: editData.accountNumber,
                accountName: editData.accountName,
            });
        }
    }, [editData]);

    const formSubmit = (val) => {
        const formData = new FormData();
        val?.fullname && formData.append("fullName", val?.fullname);
        val?.email && formData.append("email", val?.email);
        val?.password && formData.append("password", val?.password);
        val?.phoneNo && formData.append("phoneNumber", val?.phoneNo);
        val?.address && formData.append("address", val?.address);
        val?.dob && formData.append("dob", val?.dob ? moment(val.dob).format("YYYY-MM-DD") : null);
        val?.brokerName && formData.append("brokerName", val?.brokerName);
        val?.brokerServer && formData.append("serverBroker", val?.brokerServer);
        val?.accountNumber && formData.append("accountNumber", val?.accountNumber);
        val?.accountName && formData.append("accountName", val?.accountName);

        axios.put(`${process.env.REACT_APP_ADMIN_API_URL}/edit-user/${editData?.key}`, formData, {
            headers: {
                'x-product-type': 'MAKE_MY_PROFIT'
            }
        })
            .then((response) => {
                message.success("User updated successfully!");
                onClose();
                form.resetFields();
                fetchList()
            })
            .catch((error) => {
                console.error('Error:', error);
                message.error("Something went wrong");
            });
    };

    return (
        <Modal
            open={true}
            title="Edit User"
            onCancel={onClose}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={formSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Full Name"
                            name="fullname"
                            rules={[{ required: true, message: "Please Enter full name!" }]}
                        >
                            <Input placeholder="Enter Full Name" />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item label="Full Name" name="fullname" rules={[{ required: true, message: 'Please Enter full name!' }]}>
                            <Input addonBefore={<Select onChange={setuserPreference} style={{ width: 70 }} defaultValue={userPreference} options={[
                                { value: "Mr.", label: "Mr." },
                                { value: "Ms.", label: "Ms." },
                                { value: "Mrs.", label: "Mrs." },
                            ]} />} placeholder="Enter Full Name" />
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                { required: true, type: "email", message: "Please Enter valid email address!" },
                            ]}
                        >
                            <Input placeholder="Enter Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Phone Number"
                            name="phoneNo"
                            rules={[{ required: true, message: "Please Enter phone number!" }]}
                        >
                            <Input placeholder="Enter Phone Number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date of Birth"
                            name="dob"
                            rules={[{ required: true, message: "Please Enter date of birth!" }]}
                        >
                            <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: "Please Enter address!" }]}
                        >
                            <Input placeholder="Enter Address" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Broker Name" name="brokerName">
                            <Input placeholder="Enter Broker Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Broker Server" name="brokerServer">
                            <Input placeholder="Enter Broker Server" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Account Number" name="accountNumber">
                            <Input placeholder="Enter Account Number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Account Name" name="accountName">
                            <Input placeholder="Enter Account Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Save Changes
                </Button>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ loading }) => {
    // const {isLoading} = loading
    // return {isLoading}
}

export default connect(mapStateToProps, {})(UserEdit)