import React,{useEffect,useState} from 'react'
import {Tabs,Table,Space,Button,Flex,Form,Modal,Select,InputNumber,message,Image,Alert,Row,Col,Card,DatePicker,Input, Popconfirm, Typography} from "antd"
import useCustomer from '../hooks/useCustomer';
import AxiosService from '../services/APIService';
import moment from 'moment';
import {connect} from 'react-redux';
import {DeleteOutlined, FilterOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {downloadFileHandle} from '../utils/commonFunc';
const {Option} = Select;

function WithdrawReport({permission,user,isLoading}) {
  const [currentTab,setCurrentTab] = useState('1')
  const apiService = new AxiosService()
  const [historyList,setHistoryList] = useState([]);
  const [pendingList,setPendingList] = useState([]);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [filterShow,setFilterShow] = useState(false);
  const {customerList} = useCustomer({status: `["CREATED"]`})
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [searchText,setSearchText] = useState("");
  const [pendingCounter,setPendingCounter] = useState(0);
  const [historyCounter,setHistoryCounter] = useState(0);
  const [confirmProps,setConfirmProps] = useState({
    show: false,
    id: null
  });
  const onChange = (key) => {
    setCurrentTab(key)
  };
  const items = [
    {
      key: '1',
      label: `History (${historyCounter})`
    },
    {
      key: '2',
      label: `Request (${pendingCounter})`
    }
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Date and Time",
      dataIndex: "dateTime",
      key: "dateTime",
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Bot Id",
      dataIndex: "botId",
      key: "botId",
    },
    {
      title: "Account Name",
      dataIndex: "accName",
      key: "accName",
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
    },
    {
      title: "Via",
      key: "via",
      dataIndex: "via",
    },
    {
      title: "Transaction Hash",
      key: "hashId",
      dataIndex: "hashId",
    },
    {
      title: "By",
      key: "by",
      dataIndex: "by",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (dt,_) =>
        <Popconfirm
        title="Delete"
        description="Are you sure you want to delete this transaction?"
        onConfirm={() => deleteTransaction(_)}
        okText="Yes"
        cancelText="No"
      >
              
        <Button danger type='default'><DeleteOutlined /></Button>
      </Popconfirm>
    }
  ];


  const reqColumns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Date and Time",
      dataIndex: "dateTime",
      key: "dateTime",
    },
    {
      title: "Bot Id",
      dataIndex: "botId",
      key: "botId",
    },
    {
      title: "Account Name",
      dataIndex: "accName",
      key: "accName",
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Proof",
      key: "proof",
      dataIndex: "proof",
      render: (val,rec) => val && <Image width={50} src={`${process.env.REACT_APP_API_URL}/transaction/doc/${rec.key}`} />
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
    },
    {
      title: "Via",
      key: "via",
      dataIndex: "via",
    },
    {
      title: "Transaction Hash",
      key: "hashId",
      dataIndex: "hashId",
    },
    {
      title: "By",
      key: "by",
      dataIndex: "by",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "key",
      render: (e) => (permission?.transaction == "EDIT" || user?.role != "SUBADMIN") && <Space><Button size={"small"} onClick={() => {
        setConfirmProps({
          show: true,
          id: e
        })
      }}>Approve</Button><Button size={"small"} onClick={() => handleChange("REJECTED",e)} danger>Reject</Button></Space>
    }
  ];


  const deleteTransaction = (data) => {
    apiService.delete(`/transaction/delete/${data.key}`)
      .then(res => {
        message.success(res.message)
        fetchTransactionHistory({status: ["APPROVED","REJECTED"]})
      })
    .catch(e => message.error(e.message))
  }

  const handleChange = (val,id, date) => {
    apiService.post(`/transaction/change-status/${id}`,{
      status: val,
      date
    })
      .then(res => {
        fetchPendingTransaction({})
        message.success(res.message)
        setConfirmProps({
          show: false,
          id: "",
        })
        form2.resetFields()

      })
      .catch(e => message.error(e.message))
  }
  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields()
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    fetchTransactionHistory({status: ["APPROVED","REJECTED"]})
    fetchPendingTransaction({})
  },[]);

  const fetchTransactionHistory = (params) => {
    apiService.get("/transaction/list",{
      ...params,
      type: "WITHDRAW"
    })
      .then(res => {
        const rec = res.data.map((dt, i) => ({
          index: i + 1,
          key: dt._id,
          dateTime: moment(dt.created_at).format("DD-MM-YYYY HH:mm"),
          transactionId: dt.transaction_id,
          botId: dt?.cust?.cust_code,
          accName: dt?.user?.fullName,
          amount: dt.amount,
          status: dt.status,
          via: dt.via,
          by: `${dt?.by?.fullName} (${dt?.by?.role})`,
          hashId: dt.paymentId,
          transaction_date: dt.transaction_date
        }))
        setHistoryCounter(rec.length)
        setHistoryList(rec)
      })
      .catch(e => console.log(e))
  }
  const fetchPendingTransaction = (params) => {
    apiService.get("/transaction/list",{
      ...params,
      status: ["PENDING"],
      type: "WITHDRAW"
    })
      .then(res => {
        const rec = res.data.map((dt, i) => ({
          key: dt?._id,
          index: i + 1,
          dateTime: moment(dt?.created_at).format("DD-MM-YYYY HH:mm"),
          transactionId: dt?.transaction_id,
          botId: dt?.cust?.cust_code,
          accName: dt?.user?.fullName,
          amount: dt?.amount,
          status: dt?.status,
          proof: dt?.doc,
          via: dt.via,
          by: `${dt?.by?.fullName} (${dt?.by?.role})`,
          hashId: dt.paymentId
        }))
        setPendingCounter(rec.length)
        setPendingList(rec)
      })
      .catch(e => console.log(e))
  }
  const formSubmit = (val) => { 
    const currentDate = dayjs().format("DD-MM-YYYY")
    apiService.post("/transaction/create",{
      custId: val?.customer,
      amount: val?.amount,
      type: "WITHDRAW",
      from: val.from,
      transDate: dayjs(val?.date).format("DD-MM-YYYY") || currentDate
    })
      .then(res => {
        message.success(res.message)
        setIsModalOpen(false);
        fetchTransactionHistory({status: ["APPROVED","REJECTED"]})
      })
      .catch(e => message.error(e.message))
  }
  const filterHandle = (val,key) => {
    if(key === 1) {
      fetchTransactionHistory({
        date: val.date ? moment(val.date.toString()).format("DD-MM-YYYY") : undefined,
        status: val.status ? val.status : ["APPROVED","REJECTED"],
      })
    } else if(key === 2) {
      fetchPendingTransaction({
        date: val.date ? moment(val.date.toString()).format("DD-MM-YYYY") : undefined,
      })
    }
  }
  const resetFilter = (key) => {
    if(key === 1) {
      fetchTransactionHistory({status: ["APPROVED","REJECTED"]})
    } else if(key === 2) {
      fetchPendingTransaction({})

    }
    form1.resetFields()
  }

  const filterData = (searchTxt,data) => {
    return  data.filter(dt => dt?.botId?.toLowerCase().includes(searchTxt.toLowerCase()) ||
    dt?.transactionId?.toLowerCase().includes(searchTxt.toLowerCase()) ||
    dt?.accName?.toLowerCase().includes(searchTxt.toLowerCase()) ||
    dt?.hashId?.toLowerCase().includes(searchTxt.toLowerCase())
  )
  }

  const acceptTransaction = (val) => {
    handleChange("APPROVED",confirmProps.id, dayjs(val.date.toString()).format("DD-MM-YYYY"))
  }

  return (
    <>
      <Modal title="Create Transaction"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={600}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={formSubmit}
        >
          <Form.Item label="Customer" name="customer" rules={[{required: true,message: 'Select Customer!'}]}>
            <Select placeholder="Select Customer" showSearch options={customerList.map(cst => ({
              value: cst?.user?._id,
              label: `${cst?.account_number} (${cst?.user?.fullName})`
            }))} filterOption={(input,option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } />
          </Form.Item>
          <Form.Item label="Amount" name="amount" rules={[{required: true,message: 'Amount Required!'}]}>
            <InputNumber placeholder="Add Amount" min={1} style={{width: "100%"}} />
          </Form.Item>
          <Form.Item label="Withdraw from" name="from" rules={[
              {required: true, message: "Please Withdraw Type"}
            ]}>
                <Select options={[
                    {
                        label: "Profit",
                        value: "PROFIT"
                    },
                    {
                        label: "Deposit",
                        value: "DEPOSIT"
                    }
                ]} />
            </Form.Item>
            <Form.Item label="Transaction Date" name="date">
              <DatePicker defaultValue={dayjs()} style={{width: "100%"}} />
            </Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
      <Modal title="Transaction Date"
        open={confirmProps.show}
        onCancel={() => {
          setConfirmProps({
            show: false,
            id: "",
          })
        }}
        footer={[]}
        width={300}
      >
        <Form
          form={form2}
          layout="vertical"
          onFinish={acceptTransaction}
        >
          <Form.Item name="date" rules={[{required: true,message: 'Select Date!'}]}>
            <DatePicker defaultValue={dayjs()} style={{width: "100%"}} />
          </Form.Item>
          <Button htmlType='submit' type='primary'>Save</Button>
        </Form>
      </Modal>
      <Typography.Title level={3} className="page-title">Withdraw Report</Typography.Title>
      <Tabs type="card" defaultActiveKey="1" items={items} onChange={onChange} />
      {currentTab === "1" &&
        <>
          <Flex justify="end" gap={5} style={{marginBottom: "20px"}}>
            <Input value={searchText} style={{maxWidth: 200}} placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} />
            <Button type="primary" onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button>
            {(permission?.transaction == "EDIT" || user?.role != "SUBADMIN") && <Button type="primary" onClick={showModal}>
              Create Transaction
            </Button>}
            <Button type="primary" onClick={() => downloadFileHandle(historyList, 'deposit')}>
              Download
            </Button>
          </Flex>
          {filterShow && (<Card style={{marginBottom: "20px"}}>
            <Form
              form={form1}
              layout="vertical"
              onFinish={(val) => filterHandle(val,1)}
            >
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item name="date">
                    <DatePicker style={{width: "100%"}} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="status">
                    <Select allowClear placeholder="Select Status">
                      <Option value={"APPROVED"}>Approve</Option>)
                      <Option value={"REJECTED"}>Rejected</Option>)
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Space>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                    <Button type="default" onClick={() => resetFilter(1)}>Reset</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Card>)}
          {(permission?.transaction == "EDIT" || permission?.transaction == "VIEW" || user?.role != "SUBADMIN") ? <Table loading={isLoading} scroll={{ x: 1400 }} columns={columns} dataSource={searchText ? filterData(searchText,historyList) : historyList} /> : <Alert message="You havn't Enough Permission" />}

        </>}
      {currentTab === "2" && <>
        {(permission?.transaction == "EDIT" || permission?.transaction == "VIEW" || user?.role != "SUBADMIN") ? <>
          <Flex justify="end" style={{marginBottom: "20px"}}>
            <Input value={searchText} style={{marginRight: 5,maxWidth: 200}} placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} />
            <Button onClick={() => setFilterShow(!filterShow)} type="primary" style={{marginRight: 5}}><FilterOutlined /></Button>
          </Flex>
          {filterShow && (<Card style={{marginBottom: "20px"}}>
            <Form
              form={form1}
              layout="vertical"
              onFinish={(val) => filterHandle(val,2)}
            >
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item name="date">
                    <DatePicker style={{width: "100%"}} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                    <Button type="default" onClick={() => resetFilter(2)}>Reset</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Card>)}
          <Table loading={isLoading} columns={reqColumns} scroll={{ x: 1400 }} dataSource={searchText ? filterData(searchText,pendingList) : pendingList} />
        </> : <Alert message="You havn't Enough Permission" />}
      </>}
    </>
  )
}

const mapStateToProps = ({login,loading}) => {
  const {permission,user} = login
  const {isLoading} = loading
  return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(WithdrawReport)