import React, { useEffect, useState } from "react";
import { Table, Button, Flex, message, Space, Popconfirm, Typography, Tooltip } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { downloadFileHandle } from "../../utils/commonFunc";
import UserEdit from "./UserEdit";
import axios from "axios";
import moment from "moment";

function UsersList({ isLoading }) {
    const [isEditModal, setIsEditModal] = useState(false)
    const [editData, seteditData] = useState(null);
    const [userList, setUserList] = useState();

    useEffect(() => {
        fetchList()
    }, []);

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            fixed: 'left',
            width: "50px"
        },
        {
            title: "Name",
            dataIndex: "fullname",
            key: "name",
            fixed: 'left',
            width: "200px",
        },
        {
            title: "Email",
            dataIndex: "email",
            fixed: 'left',
            key: "email",
            width: "200px"
        },
        {
            title: "Phone number",
            dataIndex: "phoneNumber",
            key: "phoneNo",
            width: "250px",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: "250px",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
            width: "180px",
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            width: "100px",
            render: (e, rec) =>
                <Space>
                    <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => { seteditData(rec); setIsEditModal(true) }} />
                    <Popconfirm
                        description="Are you sure you want to close this account ?"

                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteUser(rec.key)}
                    >
                        <Tooltip title="Deactivate account status and bot status to close this account">
                            <Button icon={<CloseOutlined />} danger size={"middle"} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
        },
    ].filter(Boolean);

    const deleteUser = (id) => {
        axios.delete(`${process.env.REACT_APP_ADMIN_API_URL}/delete-user/${id}`, {
            headers: {
                'x-product-type': 'PIP_GRID'
            }
        })
            .then((res) => {
                fetchList({});
                message.success(res.message);
            })
            .catch(e => {
                message.error(e.message);
            });
    };

    const fetchList = (val) => {
        axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/lead-list`, {
            headers: {
                'x-product-type': 'PIP_GRID'
            }
        })
            .then(res => {
                const data = res.data.leads.map((dt, i) => ({
                    count: `${i + 1}`,
                    key: dt?._id,
                    fullname: `${dt?.fullName || ''}`,
                    phoneNumber: dt?.phoneNumber || '-',
                    dob: dt?.dob ? moment(dt?.dob, "YYYY-MM-DD").format("DD-MM-YYYY") : '-',
                    email: dt?.email || '-',
                    status: dt?.loginRequestStatus && "Pending",
                    address: dt?.address || ''
                }));
                setUserList(data)
            }).catch(e => {
                console.log(e);
            })
    }

    return (
        <>
            <Typography.Title level={3} className="page-title">Leads</Typography.Title>

            <Flex justify="end" gap={5} style={{ marginBottom: "20px" }}>
                <Button type="primary" onClick={() => downloadFileHandle(userList, 'users')}>
                    Download
                </Button>
            </Flex>
            <Table loading={isLoading} columns={columns} dataSource={userList} /> :
            {isEditModal && (
                <UserEdit
                    editData={editData}
                    isLoading={false}
                    onClose={() => setIsEditModal(false)}
                    fetchList={fetchList}
                />
            )}
        </>
    );
}

const mapStateToProps = ({ login, loading }) => {
    const { permission, user } = login
    const { isLoading } = loading
    return { permission, user, isLoading }
}

export default connect(mapStateToProps, {})(UsersList);