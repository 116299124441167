import React,{useEffect, useState} from 'react'
import leadLogo from "../../assets/leadLogo.png"
import {Button,Checkbox,Col,DatePicker,Flex,Form,Input,message,Modal,Row,Select,Typography} from 'antd'
import country from '../../data/country'
import AxiosService from '../../services/APIService';
import {useSearchParams} from 'react-router-dom';
import ContactInput from '../../components/ContactInput';
const {Title, Text} = Typography;
function Leads() {
    const apiService = new AxiosService()
    const [otpModal,setOtpModal] = useState(false);
    const [inquiryId,setInquiryId] = useState("");
    const [searchParams] = useSearchParams();
    const white_label = searchParams.get("wlid")
    const [form] = Form.useForm()
    const [otp,setOtp] = useState('');
    const [isChecked,setIsChecked] = useState(false);
    const [whiteLabel, setWhiteLabel] = useState(null);
    const [loading,setLoading] = useState({
        formLoading: false,
        otpLoading: false
    });
    const onFinish = (val) => {
        setLoading({...loading, formLoading: true})
        apiService.post('/inquire/create',{...val,white_label})
            .then(({data}) => {
                // setOtpModal(true)
                // setInquiryId(data._id);
                // setIsChecked(false)
                message.success("Lead Create Successfully")
                form.resetFields()
                successMessage()
            })
            .catch(e => {
                message.error(e.message)
            })
            .finally(() => {
                setLoading({...loading, formLoading: false})
        })
    }
    const onChange = (text) => {
        setLoading({...loading, otpLoading: true})
        apiService.post('/inquire/otp-verify',{id: inquiryId,otp: text})
            .then(() => {
                setOtpModal(false)
            })
            .catch(e => {
                message.error(e.message)
            })
            .finally(() => {
                setLoading({...loading, otpLoading: false})
        })

        setOtp(text)
    };
    const otpVerificationHandle = () => {
    }

    const successMessage = () => {
        Modal.success({
            content: (
                <>
                    <Typography.Title level={3}>Thank You for Registering!</Typography.Title>
                    <Typography.Paragraph>You have successfully registered for the 1-month free trial of our forex auto-trading bot.Please check your email inbox for further details on how to start using the trial.</Typography.Paragraph>
                    <Typography.Paragraph>If you have any questions, feel free to contact our support team.</Typography.Paragraph>
                </>
            ),
            footer: [],
            closable: true
        });
    };
    const handleCheckbox = (e) => {
        setIsChecked(e.target.checked)
    }
    const sharedProps = {
        onChange,
        length: 4
    };
    useEffect(() => {
        fetchWhitelabel()
    },[]);
    const fetchWhitelabel = () => {
        apiService.get(`white-label/get/${white_label}`)
        .then(res => {
            setWhiteLabel(res.data)
        })
    }
    return (
        <>
            <div className='lead-container'>
                <Modal open={otpModal} footer={[]} width={300} closable={false}>
                    <div style={{display: "flex",flexDirection: "column",alignItems: 'center'}}>
                        <Form
                            name="lead"
                            onFinish={onFinish}
                            autoComplete="off"
                            layout='vertical'
                            form={form}
                        >
                            <Title level={5}>Enter OTP</Title>
                            <Text style={{fontSize: 12, lineHeight: 1.3,marginBottom: 25, display: "inline-block"}}>For OTP Please Check You Mail Inbox And Enter Here.</Text>
                            <Form.Item
                                name="otp"
                                style={{marginBottom: 0}}
                            >
                                <Input.OTP {...sharedProps} style={{marginBottom: 10, width: "100%"}} />
                            </Form.Item>
                            <Button disabled={!otp} onClick={otpVerificationHandle} type="default" className='submit-btn' block size='large' loading={loading.otpLoading}>
                                Verify
                            </Button>
                        </Form>
                    </div>
                </Modal>
                <div className='lead-content'>
                        <div className='logo-wrapper'>
                            <img src={`${process.env.REACT_APP_API_URL}white-label/logo/${white_label}`} className='logo' />
                        </div>
                    <div className='lead-logo-wrapper'>
                        <h1>1 Month Free Trial</h1>
                        <p>Please fill in your details below to complete your registration.</p>
                    </div>
                </div>
                <div className='lead-form-block'>
                    <div className='form-block'>
                        <Form
                            name="lead"
                            onFinish={onFinish}
                            autoComplete="off"
                            layout='vertical'
                            form={form}
                        >
                            <Row gutter={20}>
                                <Col span={24}>
                                    <Form.Item
                                        label="I Want to Register as"
                                        name="register_as"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Option',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select a Option"
                                            size='large'
                                            options={[
                                                {
                                                    value: 'Trader',
                                                    label: 'Trader',
                                                },
                                                {
                                                    value: 'IB',
                                                    label: 'IB',
                                                },
                                                {
                                                    value: 'Investor',
                                                    label: 'Investor',
                                                },
                                                {
                                                    value: 'Broker',
                                                    label: 'Broker',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        label="Prefix"
                                        name="prefix"
                                    >
                                        <Select
                                            placeholder="Select a Option"
                                            defaultValue="Mr"
                                            size='large'
                                            options={[
                                                {
                                                    value: 'Mr',
                                                    label: 'Mr',
                                                },
                                                {
                                                    value: 'Ms',
                                                    label: 'Ms',
                                                },
                                                {
                                                    value: 'Mrs',
                                                    label: 'Mrs',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={19}>
                                    <Form.Item
                                        label="Full Name"
                                        name="full_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Name',
                                            },
                                        ]}
                                    >
                                        <Input size='large' placeholder='Enter your full name' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Date of Birth (DOB)"
                                        name="dob"
                                    >
                                        <DatePicker size='large' style={{width: "100%"}} placeholder='Enter your DOB' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <ContactInput require={true} form={form} name='phone_number' placeholder={"(000) 000-0000"} label='Phone Number' />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Email Address"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Email',
                                                type: "email"
                                            }
                                        ]}
                                    >
                                        <Input size='large' placeholder='Enter your email' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Country"
                                        name="country"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Country',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            size='large'
                                            placeholder="Select a Country"
                                            options={[...new Set(country.map(item => item.name))].map(ctr => ({
                                                value: ctr,
                                                label: ctr,
                                            }))}
                                            filterOption={(input,option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="City"
                                        name="city"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter City'
                                            }
                                        ]}
                                    >
                                        <Input size='large' placeholder='Enter your City' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="tcAgreement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                              validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                            },
                                          ]}
                                    >
                                        <Flex align='start' gap={5}>
                                            <Checkbox onChange={handleCheckbox} checked={isChecked}/>
                                            <Typography.Paragraph
                                                ellipsis={{
                                                rows: 2,
                                                    expandable: 'collapsible',
                                                }}
                                            >
                                                An investment in derivatives may mean investors may lose an amount even greater than their original investment. Anyone wishing to invest in any of the products mentioned in {whiteLabel?.name} should seek their own financial or professional advice. Al Trading may not be suitable for everyone and involves the risk of losing part or all of your money. Trading in the financial markets has large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest in the markets. Don't invest and trade with money which you can't afford to lose. CFD Trading are not allowed in some countries, before investing your money, make sure whether your country is allowing this or not. You are strongly advised to obtain independent financial, legal advice before proceeding with any currency or spot metals trade. Nothing in this site should be read or construed as constituting advice on the part of {whiteLabel?.name} Limited or any of its affiliates, directors, officers or employees.
                                            </Typography.Paragraph>
                                        </Flex>
                                    </Form.Item>
                                </Col>
                                {isChecked &&
                                    <Col>
                                        <Form.Item
                                            label={`What percentage of risk are you willing to allow ${whiteLabel?.name} to take with your capital in your live trading account? Please select the agreed percentage.`}
                                            name="risk_pr"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Percentage'
                                                }
                                            ]}
                                        >
                                            <Select
                                                size='large'
                                                placeholder="Select a Percentage"
                                                options={[
                                                    {
                                                        label: "40%",
                                                        value: 40
                                                    },
                                                    {
                                                        label: "50%",
                                                        value: 50
                                                    },
                                                    {
                                                        label: "60%",
                                                        value: 60
                                                    },
                                                    {
                                                        label: "80%",
                                                        value: 80
                                                    },
                                                    {
                                                        label: "100%",
                                                        value: 100
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Button type="default" htmlType="submit" className='submit-btn' block size='large' loading={loading.formLoading}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Leads