import React, { useEffect, useState } from "react";
import { Table, Select, Modal, Button, Flex, Upload, message, Space, Popconfirm, Typography, Tooltip } from "antd";
import { InboxOutlined, CloseOutlined, EditOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { downloadFileHandle } from "../../utils/commonFunc";
import UserEdit from "./UserEdit";
import axios from "axios";
import moment from "moment";

const { Dragger } = Upload;

function UsersList({ permission, user, isLoading }) {
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [isEditModal, setIsEditModal] = useState(false);
    const [editData, seteditData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        fetchList()
    }, []);

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            fixed: 'left',
            width: "50px"
        },
        {
            title: "Account Name",
            dataIndex: "accountName",
            key: "accountName",
            fixed: 'left',
            width: "200px",
        },
        {
            title: "Account Number",
            dataIndex: "accountNumber",
            fixed: 'left',
            key: "accNo",
            width: "200px"
        },
        {
            title: "Name",
            key: "fullname",
            dataIndex: "fullname",
            width: "230px"
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (e, rec) => <>
                <Select
                    defaultValue={e}
                    value={e}
                    style={{ width: "100%" }}
                    onChange={(val) => statusChange(val, rec)}
                    options={[
                        { value: 'ACTIVE', label: 'Activate' },
                        { value: 'INACTIVE', label: 'Inactivate' }
                    ]}
                />
            </>,
            width: "170px"
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            width: "150px",
            render: (e, rec) => (permission?.customer === "EDIT" || user?.role !== "SUBADMIN") &&
                <Space>
                    <Button type="default" icon={<UploadOutlined />} size={"middle"} onClick={() => uploadOpen(rec)} />
                    <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => { seteditData(rec); setIsEditModal(true) }} />
                    <Popconfirm
                        description="Are you sure you want to close this account ?"

                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteUser(rec.key)}
                    >
                        <Tooltip title="Deactivate account status to close this account">
                            <Button icon={<CloseOutlined />} danger size={"middle"}
                            // disabled={rec.record.botStatus || rec.record.user.status == "ACTIVE"} 
                            />
                        </Tooltip>
                    </Popconfirm>
                </Space>
        },
        {
            title: "Mail",
            dataIndex: "email",
            key: "mail",
            width: "250px",
        },
        {
            title: "Phone number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            width: "220px",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: "250px",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
            width: "180px",
        },
        {
            title: "Password",
            dataIndex: "password",
            key: "password",
            width: "150px",
        },
        {
            title: "Broker Name",
            dataIndex: "brokerName",
            key: "brokerName",
            width: "200px",

        },
        {
            title: "Server Name",
            dataIndex: "serverBroker",
            key: "serverName",
            width: "200px",
        },
        {
            title: "Product Name",
            dataIndex: "productName",
            key: "productName",
            width: "200px",
        },
        {
            title: "Paid Amount",
            dataIndex: "paidAmount",
            key: "paidAmount",
            width: "180px",
            align: 'center'
        },
    ].filter(Boolean);

    const uploadOpen = (rec) => {
        setSelectedUser(rec);
        setIsUploadOpen(true);
        getUploadedFiles(rec);
    };

    const statusChange = (val, rec) => {
        axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/update-status`, {
            userId: rec?.key,
            status: val
        }, {
            headers: {
                'x-product-type': 'MAKE_MY_PROFIT'
            }
        })
            .then(res => {
                message.success(res.message);
                fetchList({});
            })
            .catch(e => message.error(e.message));
    };

    const fetchList = () => {
        axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/approved-users`, {
            headers: {
                'x-product-type': 'MAKE_MY_PROFIT'
            }
        })
            .then(res => {
                const data = res.data.users.map((user, index) => ({
                    count: index + 1,
                    key: user?._id,
                    fullname: user?.fullName || "-",
                    phoneNumber: user?.phoneNumber || "-",
                    dob: user?.dob ? moment(user?.dob, "YYYY-MM-DD").format("DD-MM-YYYY") : "-",
                    email: user?.email || "-",
                    loginRequestStatus: user?.loginRequestStatus,
                    status: user?.status,
                    address: user?.address || "-",
                    accountName: user?.accountName === "undefined" ? "-" : `${user?.accountName}`,
                    accountNumber: user?.accountNumber === "undefined" ? "-" : `${user?.accountNumber}`,
                    brokerName: user?.brokerName === "undefined" ? "-" : `${user?.brokerName}`,
                    serverBroker: user?.serverBroker === "undefined" ? "-" : `${user?.serverBroker}`,
                    password: user?.password || "!Pa$$word",
                    productName: user?.productName || '',
                    paidAmount: user?.paidAmount || '',
                }));
                setUserList(data);
            }).catch(error => {
                message.error("Failed to fetch user list");
                console.error(error);
            })
    }

    const deleteUser = (id) => {
        axios.delete(`${process.env.REACT_APP_ADMIN_API_URL}/delete-user/${id}`, {
            headers: {
                'x-product-type': 'MAKE_MY_PROFIT'
            }
        })
            .then((res) => {
                fetchList({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }

    const fileListColumn = [
        {
            title: "Filename",
            key: "name",
            width: "80%",
            render: (file) => file?.name || file?.fileName || "N/A",
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            render: (_, file) => {
                return <Flex align="center" gap={10} justify="center">
                    <DeleteOutlined onClick={() => handleDeleteFile(file)} style={{ color: 'red' }} />
                </Flex>
            }
        },
    ];

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleUpload = () => {
        if (fileList.length === 0) {
            message.warning("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        fileList.forEach(file => {
            formData.append("files", file.originFileObj);
        });

        axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/upload-file/${selectedUser?.key}`, formData, {
            headers: {
                'x-product-type': 'MAKE_MY_PROFIT'
            }
        })
            .then(() => {
                message.success("Files uploaded successfully");
                setIsUploadOpen(false);
                setFileList([]);
                getUploadedFiles(selectedUser);
            })
            .catch(e => message.error("File upload failed"));
    };

    const getUploadedFiles = (rec) => {
        axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/uploaded-files/${rec?.key}`, {
            headers: {
                'x-product-type': 'MAKE_MY_PROFIT'
            }
        })
            .then(res => {
                setExistingFiles(res.data.uploadedFiles || []);
            })
            .catch(e => message.error("Error fetching uploaded files"));
    };

    const handleDeleteFile = (file) => {
        if (!file) {
            message.error("Invalid file.");
            return;
        }

        if (file.uid) {
            setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
            message.success("File removed from upload queue.");
            return;
        }

        if (file._id && selectedUser?.key) {
            axios
                .delete(`${process.env.REACT_APP_ADMIN_API_URL}/delete-file/${selectedUser.key}/${file._id}`, {
                    headers: {
                        'x-product-type': 'MAKE_MY_PROFIT'
                    }
                })
                .then((res) => {
                    message.success(res.data.message || "File deleted successfully");
                    setExistingFiles((prevFiles) => prevFiles.filter((f) => f._id !== file._id));
                })
                .catch((error) => {
                    message.error(error.response?.data?.error || "Failed to delete the file");
                    console.error("Error deleting file:", error);
                });
        }
    };

    return (
        <>
            <Modal title="Upload File" open={isUploadOpen} onCancel={() => setIsUploadOpen(false)} footer={[]} width={600}>
                <Dragger
                    fileList={fileList}
                    onChange={handleChange}
                    beforeUpload={() => false}
                    showUploadList={false}
                // accept=".zip"
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                        banned files.
                    </p>
                </Dragger>
                <Table
                    columns={fileListColumn}
                    dataSource={[...(existingFiles || []), ...(fileList || [])]}
                    rowKey={(file) => file?._id || file?.uid || Math.random()}
                    style={{ marginTop: 16 }}
                />
                <Button type="primary" onClick={handleUpload}>
                    Upload
                </Button>
            </Modal>
            <Typography.Title level={3} className="page-title">List</Typography.Title>

            <Flex justify="end" gap={5} style={{ marginBottom: "20px" }}>
                {/* <Input style={{ maxWidth: 200 }} placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} /> */}
                {/* <Button type="primary" onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button> */}
                {/* {(permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Button type="primary" onClick={showModal}>
                    Create User
                </Button>} */}
                <Button type="primary" onClick={() => downloadFileHandle(userList, 'users')}>
                    Download
                </Button>
            </Flex>
            <Table loading={isLoading} scroll={{ x: 2000 }} columns={columns} dataSource={userList} />
            {isEditModal && (
                <UserEdit
                    editData={editData}
                    isLoading={false}
                    onClose={() => setIsEditModal(false)}
                    fetchList={fetchList}
                />
            )}
        </>
    );
}
const mapStateToProps = ({ loading }) => {
    const { isLoading } = loading
    return { isLoading }
}
export default connect(mapStateToProps, {})(UsersList);